<template>
  <div>
    <section class="mb-4">
      <div class="banner" :class="isDesk ? 'container' : ''">
        <carousel
          :per-page="1"
          :mouse-drag="false"
          :autoplay="true"
          :loop="true"
          :navigationEnabled="false"
          :paginationEnabled="false"
          :autoplayTimeout="5000"
        >
          <slide
            :data-index="item.index"
            :data-name="item.name"
            :key="index"
            v-for="(item, index) in banner.items"
          >
            <!-- @click.prevent="$router.push({ name: 'product-list', params : { categoria: 'BAG' } })" -->
            <img
              class="carouselImage"
              :src="item.url"
              :alt="item.name"
              @click="goToRoute(item)"
            />
            <!--  -->
          </slide>
        </carousel>
      </div>
    </section>

    <section class="cat">
      <div class="container">
        <div class="row">
          <div class="col">
            <img
              :src="
                isDesk
                  ? require('@/assets/img/roupas-e-acessorios-desk.png')
                  : require('@/assets/img/roupas-e-acessorios.png')
              "
              @click.prevent="
                $router.push({
                  path: 'roupas-e-acessorios',
                })
              "
            />
          </div>

          <div class="col">
            <img
              :src="
                isDesk
                  ? require('@/assets/img/motopecas-desk.png')
                  : require('@/assets/img/motopecas.png')
              "
              @click.prevent="
                $router.push({
                  path: 'motopecas',
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <section class="container p-0 cpc">
      <h1 class="h4 pl-3">Compre por Categoria:</h1>

      <div class="container">
        <carousel
          :perPageCustom="[
            [320, 3],
            [375, 4],
            [768, 8],
            [1024, 12],
          ]"
          :navigationEnabled="true"
          :paginationEnabled="false"
        >
          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'ACESSÓRIOS' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/acessorios.png')
                    : require('@/assets/img/categorias/acessorios.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Acessórios</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'BAG' }, //BAG
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/bag.png')
                    : require('@/assets/img/categorias/bag.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Bag</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'ELÉTRICA' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/eletrica.png')
                    : require('@/assets/img/categorias/eletrica.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Elétrica</p>
          </slide>

          <!-- <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'FREIO' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/freio.png')
                    : require('@/assets/img/categorias/freio.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Freio</p>
          </slide> -->

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'ILUMINAÇÃO' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/iluminacao.png')
                    : require('@/assets/img/categorias/iluminacao.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Iluminação</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'KITS' }, //KITS
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/kit.png')
                    : require('@/assets/img/categorias/kit.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Kit</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'MOTOR' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/motor.png')
                    : require('@/assets/img/categorias/motor.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Motor</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'PNEUS' }, //PNEUS
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/pneus-e-camaras.png')
                    : require('@/assets/img/categorias/pneus-e-camaras.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Pneus e Câmaras</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'RETROVISORES' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/retrovisor.png')
                    : require('@/assets/img/categorias/retrovisor.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Retrovisor</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'SUSPENSÃO' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/suspensao.png')
                    : require('@/assets/img/categorias/suspensao.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Suspensão</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'TRANSMISSÃO' },
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/transmissao.png')
                    : require('@/assets/img/categorias/transmissao.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Transmissão</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'VESTUÁRIO' }, //VESTUÁRIO
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/vestuario.png')
                    : require('@/assets/img/categorias/vestuario.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Vestuário</p>
          </slide>

          <slide class="slide">
            <div
              class="category-img"
              @click.prevent="
                $router.push({
                  name: 'product-list',
                  params: { categoria: 'CALÇADOS' }, //CALÇADOS
                })
              "
            >
              <img
                :src="
                  isDesk
                    ? require('@/assets/img/categorias/calcados.png')
                    : require('@/assets/img/categorias/calcados.png')
                "
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Calçados</p>
          </slide>
        </carousel>
      </div>
    </section>

    <section
      class="container p-0 mb-4"
      v-if="
        productsList.some(
          (item) =>
            item.product &&
            item.product.categoria &&
            item.product.categoria?.descricao &&
            (item.product.categoria?.descricao?.includes('ÓLEO') ||
              item.product.categoria?.descricao?.includes('PNEUS'))
        )
      "
    >
      <h1 class="h4 pl-3">Destaques em Motopeças:</h1>
      <div class="container horizontal-scrollable">
        <div class="row no-gutters">
          <div v-for="(product, index) in productsList" :key="index">
            <div
              :style="
                product.categoria.descricao == 'PNEUS'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'PNEU'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'OLEO'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'OLEOS'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'ÓLEOS'
                  ? ''
                  : 'display: none;'
              "
              class="card"
              @click.prevent="
                goToProduct(product.categoria.descricao, product.descricao, product.id)
              "
            >
              <div
                class="card-img"
                v-if="product.imagem[0] && product.imagem[0].link"
                :style="`background-image: url(${ajustarImg(product.imagem[0].link)})`"
              ></div>
              <div
                class="card-img"
                v-else
                :style="`background-image: url(${product.imageThumbnail})`"
              ></div>
              <div class="card-content">
                <p class="text-truncate">{{ product.descricao }}</p>
                <h5>
                  {{
                    ((product.preco / 100) * 95)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                  <span style="font-size: 8px"> no Pix </span>
                </h5>
                <small
                  >ou 3 x de
                  {{
                    (product.preco / 3)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container p-0 mb-4 pd">
      <h1 class="h4 pl-3 mt-4">Produtos em Destaque:</h1>
      <carousel
        :perPageCustom="[
          [425, 3],
          [768, 4],
          [1024, 6],
          [1440, 7],
        ]"
        :navigationEnabled="true"
        :paginationEnabled="false"
      >
        <slide
          :style="
            product.categoria.descricao == 'PNEUS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'PNEU'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEO'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEOS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'ÓLEOS'
              ? 'display: none;'
              : ''
          "
          v-for="(product, index) in productsList"
          :key="index"
        >
          <div
            :style="
              product.categoria.descricao == 'PNEUS'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'PNEU'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'OLEO'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'OLEOS'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'ÓLEOS'
                ? 'display: none;'
                : ''
            "
            class="card"
            @click.prevent="
              goToProduct(product.categoria.descricao, product.descricao, product.id)
            "
          >
            <div
              class="card-img"
              v-if="product.imagem[0] && product.imagem[0].link"
              :style="`background-image: url(${ajustarImg(product.imagem[0].link)})`"
            ></div>
            <div
              class="card-img"
              v-else
              :style="`background-image: url(${product.imageThumbnail})`"
            ></div>
            <div class="card-content">
              <p class="text-truncate">{{ product.descricao }}</p>
              <h5 class="mb-2">
                <span class="price" v-if="!product.precoPromocional">
                  {{
                    ((product.preco / 100) * 95)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                </span>
                <span class="price" v-else>
                  <span class="normal-price">
                    <span class="dashed">
                      {{
                        ((product.preco / 100) * 95)
                          | currency("R$ ", 2, {
                            thousandsSeparator: ".",
                            decimalSeparator: ",",
                          })
                      }}
                    </span>

                    <span class="pct">
                      <img src="@/assets/img/arrow-down.svg" alt="" />
                      <span>
                        {{
                          calculateDiscountPercentage(
                            product.preco,
                            product.precoPromocional
                          )
                        }}%
                      </span>
                    </span>
                  </span>
                  <span class="promo-price">
                    {{
                      ((product.precoPromocional / 100) * 95)
                        | currency("R$ ", 2, {
                          thousandsSeparator: ".",
                          decimalSeparator: ",",
                        })
                    }}
                  </span>
                </span>
                <span style="font-size: 14px; display: block"> À vista no PIX ou </span>
              </h5>

              <small>
                <span v-if="!product.precoPromocional">
                  {{
                    product.preco
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                </span>
                <span v-else>
                  {{
                    product.precoPromocional
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                </span>
                no cartão de crédito em até 3x sem juros
              </small>
            </div>
          </div>
        </slide>
      </carousel>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      banner: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      productsList: "productsList",
      productsListLoading: "productsListLoading",
      getUser: "getUser",
    }),
    isDesk() {
      return this.windowWidth >= 768;
    },
  },
  methods: {
    ...mapActions({
      fetchProductsList: "fetchProductsList",
      getUserFromIFood: "getUserFromIFood",
      fetchBanner: "fetchBanner",
    }),

    ajustarImg(img) {
      return img.replace("http:", "https:");
    },

    goToRoute(item) {
      if (item.link.includes("https://")) {
        window.location.href = item.link;
      } else {
        if (item.link.includes("product-list")) {
          this.$router.push({
            name: "product-list",
            params: { categoria: item.link.replace("#/product-list/", "") },
          });
        } else {
          if (item.link.includes("product"))
            this.$router.push({
              name: "product",
              params: { id: item.link.replace("#/product/", "") },
            });
          else
            this.$router.push({
              path: item.link.replace("#/", ""),
            });
        }
      }
    },

    goToProduct(cat, nam, id) {
      localStorage.setItem("gtpId", id);

      const alt = (x) => {
        return x
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9\s]/g, "")
          .replace(/\s+/g, "-")
          .toLowerCase();
      };

      const newCategory = alt(cat);
      const newName = alt(nam);

      this.$router.push({
        name: "product",
        params: { category: newCategory, name: newName, id: id },
      });
    },

    updateWidth() {
      this.windowWidth = window.innerWidth;
    },

    calculateDiscountPercentage(originalPrice, promotionalPrice) {
      if (!promotionalPrice || promotionalPrice === originalPrice) {
        return 0;
      }
      const discount = (originalPrice - promotionalPrice) / originalPrice;
      return (discount * 100).toFixed(0);
    },
  },

  async beforeCreate() {
    var homeAfterCheckout = localStorage.getItem("ifFac");
    if (homeAfterCheckout === "true") {
      localStorage.setItem("ifFac", "false");
      location.reload();
      return;
    }
  },

  async created() {
    const queryString = decodeURI(window.location.search);
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("public_id")) {
      localStorage.setItem("worker_id", urlParams.get("public_id"));

      try {
        await this.getUserFromIFood({ user_id: urlParams.get("public_id") });
        this.banner = await this.fetchBanner({
          region: this.getUser.region,
          user_id: urlParams.get("public_id"),
        });
        //this.banner = JSON.parse(this.banner)

        const productId = urlParams.get("product");
        const categoryId = urlParams.get("category");

        if (productId) {
          this.$router.replace({
            name: "product",
            params: { categoria: "", name: "", id: productId },
          });
        } else if (categoryId) {
          this.$router.replace({
            name: "product-list",
            params: { categoria: categoryId, name: "", id: "" },
          });
        } else {
          this.banner = await this.fetchBanner({
            region: this.getUser.region,
            user_id: urlParams.get("public_id"),
          });
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      this.banner.items = [
        {
          id: "Banner sem ID",
          name: "Banner sem ID",
          status: "ativo",
          url: require("@/assets/img/BANNER_HOME.jpg"),
          region: ["SP - Sao_Paulo"],
          link: "https://entregador.ifood.com.br/app/v3/driver_store",
        },
      ];
    }
  },

  async mounted() {
    this.fetchProductsList({ limit: 25, situacao: "Ativo" });
    window.addEventListener("resize", this.updateWidth);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .VueCarousel {
  .VueCarousel-navigation,
  .VueCarousel-pagination {
    display: none;
  }
}

.carouselImage {
  border: none;
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
  //box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);
  max-width: 100%;
}

.category-img {
  margin: auto;

  img {
    border-radius: 50%;

    @media (max-width: 768px) {
      border-radius: unset;
    }
  }
}

.cat {
  margin-bottom: 25px;

  img {
    width: 100%;
  }
}

::v-deep .cpc {
  .slide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  .VueCarousel-navigation {
    display: unset;

    .VueCarousel-navigation-button {
      color: red;
    }

    .VueCarousel-navigation--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

::v-deep .pd {
  .VueCarousel-slide {
    margin: 15px 0;
    display: flex;
    // align-items: center;
    justify-content: center;
  }

  .VueCarousel-navigation {
    display: unset;

    .VueCarousel-navigation-button {
      color: red;
    }

    .VueCarousel-navigation--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

::v-deep .VueCarousel-navigation {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.card {
  border: none;
  padding: 0;
  border-radius: 5px;
  width: 90%;
  box-shadow: 0 3px 6px 4px rgba(0, 0, 0, 0.1) !important;
  align-items: flex-start;

  &-img {
    width: 135px;
    height: 135px;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    position: relative;
    border-radius: 5px;
    background: no-repeat center center / cover;

    @media (max-width: 425px) {
      width: 100%;
    }

    @media (max-width: 412px) {
      width: 100%;
      min-height: 180px;
    }

    @media (max-width: 375px) {
      width: 100%;
      min-height: 180px;
    }

    @media (max-width: 320px) {
      width: 100%;
      min-height: 150px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 135px;
    }
  }

  &-content {
    font-size: 0.75rem;
    padding: 0.5rem;
    white-space: normal;

    p {
      margin-bottom: 0.25rem;
    }

    h5 {
      margin: 0;
    }
  }
}

.text-truncate {
  max-width: 115px;
}

.price {
  width: 100%;
  justify-content: space-between;

  .normal-price {
    width: 100%;
    justify-content: space-between;
    font-size: 14px;

    .pct {
      padding: 3px 10px;
      font-size: 12px;
    }
  }
  .promo-price {
    .por {
      font-size: 12px;
    }
  }
}
</style>
